import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"


const data = [
  {
    id: 1,
    text: "Pradžia",
    url: "/",
  },
  {
    id: 2,
    text: "Apie Cornhole",
    url: "/apie-cornhole/",
  },
  {
    id: 3,
    text: "Taisyklės",
    url: "/taisykles/",
  },
  {
    id: 4,
    text: "Cornhole nuoma",
    url: "/cornhole-nuoma/",
  },
  {
    id: 5,
    text: "Kainos",
    url: "/kainos/",
  },
  {
    id: 6,
    text: "Apie mus",
    url: "/apie-mus/",
  },
  {
    id: 7,
    text: "Kontaktai",
    url: "/kontaktai/",
  },
  {
    id: 7,
    text: "Turnyras",
    url: "/turnyras/",
  },
];


const Links = () => {
  return (
    <NavList>
        {data.map(link => {
        return (
        <NavListItem key={link.id}>
        <Link activeStyle={{
          color: "#f72585"
        }} to={link.url}>{link.text}</Link>
        </NavListItem>
        )
      })}
    </NavList>
  )
};

export default Links;

const NavList = styled.ul`
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const NavListItem = styled.li`
    text-decoration: none;
    margin-left: 2rem;
    list-style: none;
    font-size: 21px !important;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 16px !important;
    }
    
    :last-child{
        display: inline-block;
        background: transparent;
        white-space: nowrap;
        padding: ${({big}) => (big ? '12px 21px' : '10px 18px')};
        color: ${props => props.theme.white};
        font-size: ${({big}) => (big ? '18px' : '16px')};
        letter-spacing: 1.2px;
        line-height: 1.65;
        font-weight: 700;
        outline: none;
        border: 2px solid ${props => props.theme.primary};
        min-width: 50px;
        cursor: pointer;
        text-decoration: none;
        border-radius: 5px;
        // If framer motion is used delete this
        transition: 80ms !important;
        
        &:hover {
        background: ${props => props.theme.primary};
          a{
            color: white !important;
          }
        }
        
        a {
          color: ${props => props.theme.primary};
          :hover {
            color: ${props => props.theme.white};
            box-shadow: none;
           }
           :visited{
            color: ${props => props.theme.primary};
           }
          
        }
     }
    
    a {
     position:relative;
     z-index: 1;
     color: ${props => props.theme.black};
     font-weight: 700;
     letter-spacing: 0.25px;
     padding: 0.5rem 0rem;
     text-decoration: none;
     transition: 100ms;
     font-size: 16px !important;
     
     :hover {
      color: ${props => props.theme.primary};
      box-shadow: 0px 1.6px ${props => props.theme.primary};
     }
     
     @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
      font-size: 18px !important;
    }
     
     
    }
`;
