import React, {useEffect, useState} from "react"
import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar";
import {GlobalStyle} from "../styles/GlobalStyles";
import {GlobalFonts} from "../assets/fonts/GlobalFonts";
import styled, {ThemeProvider} from "styled-components";



const Layout = ({ children }) => {

    const [menuState, setMenuState] = useState(false);



    useEffect(() => {
        document.getElementsByTagName("body")[0].style.visibility = "visible";
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

    }, []);

    const theme = {
        primary: '#f72585',
        primaryDarken: '#D90866',
        secondary: '#1DBCED',
        secondaryDarken: '#11A3D0',
        black: '#181518',
        dark: '#3b3b3b',
        white: '#ffffff',
        light: '#f9f9f9',
        gradientWhite: 'radial-gradient(ellipse at right top, #f9f9f9 0%, #e1e4e8 55%, #d1d6db 100%)',
        breakpoints: {
            xs: '320px',
            phone: '425px',
            tablet: '768px',
            laptop: '1024px',
            desktop: '1440px',
            desktopL: '1920px',
        },
    };

  return (
    <ThemeProvider theme={theme} name="theme-color" content="#a62339">

        <GlobalStyle/>
        <GlobalFonts/>
        <PageContainer>
            <Header menuState={menuState} setMenuState={setMenuState}/>
            <Sidebar menuState={menuState} setMenuState={setMenuState}/>
            <MainWrapper>{children}</MainWrapper>
            <Footer/>
        </PageContainer>
    </ThemeProvider>
  )
};

export default Layout;

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
    background-color: white;
`;

const MainWrapper = styled.main`
  flex: 1;
`;

