import styled, {css} from "styled-components"
import {Link} from "gatsby"
import {motion} from "framer-motion";

export const Button = styled(motion(Link))`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 21px' : '10px 18px')};
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-size: ${({big}) => (big ? '18px' : '16px')};
  letter-spacing: 0.5px;
  line-height: 1.65;
  font-weight: 700;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryDarken};
  transform: translateY(-2px);
  color: ${props => props.theme.white};
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    padding: ${({big}) => (big ? '14px 18px' : '12px 16px')};
    font-size: ${({big}) => (big ? '16px' : '14px')};
  }
`;

export const ButtonA = styled(motion.a)`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 21px' : '10px 18px')};
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-size: ${({big}) => (big ? '18px' : '16px')};
  letter-spacing: 0.5px;
  line-height: 1.65;
  font-weight: 700;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryDarken};
  color: ${props => props.theme.white};
  transform: translateY(-2px);
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    padding: ${({big}) => (big ? '14px 18px' : '12px 16px')};
    font-size: ${({big}) => (big ? '16px' : '14px')};
  }
`;

export const ButtonSubmit = styled(motion.button)`
  display: inline-block;
  background: ${props => props.theme.primary};
  white-space: nowrap;
  padding: ${({big}) => (big ? '12px 21px' : '10px 18px')};
  color: ${props => props.theme.white};
  text-transform: uppercase;
  font-size: ${({big}) => (big ? '18px' : '16px')};
  letter-spacing: 1.2px;
  line-height: 1.65;
  font-weight: 700;
  outline: none;
  border: none;
  min-width: 50px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  // If framer motion is used delete this
  transition: 80ms !important;
  
  &:hover {
  background: ${props => props.theme.primaryDarken};
  transform: translateY(-2px);
   color: ${props => props.theme.white};
  }
  
  ${props => props.centered && css`
    display: block;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;
  `}
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
    
    &:hover {
      background: ${props => props.theme.secondaryDarken};
      transform: translateY(-2px);
    }
  `}
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    padding: ${({big}) => (big ? '14px 18px' : '12px 16px')};
    font-size: ${({big}) => (big ? '16px' : '14px')};
  }
`;