import {createGlobalStyle, css} from "styled-components";
import normalize from "styled-normalize";
import styled from "styled-components";
import {motion} from "framer-motion";

export const GlobalStyle = createGlobalStyle`
  
  ${normalize}
  
  *,
  ::after,
  ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    
  }
  
  body {
    font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: ${props => props.theme.white};
    background-color: ${props => props.theme.black};
    line-height: 1.65;
    font-size: 0.875rem;
    overscroll-behavior: none;
    overflow-x: hidden;
    max-width: 100%;
    visibility: hidden;
  }
  
  a {
    font-size: clamp(14px, 3vw, 18px);
    margin-bottom: 1.25rem;
    font-weight: 700;
    color: ${props => props.theme.secondary};
    :hover{
      color: ${props => props.theme.secondaryDarken};
      }
  }
  
  ul {
    list-style: none;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: clamp(14px, 3vw, 16px);
    margin-bottom: 1.25rem;
  }
  
  li {
    font-size: clamp(14px, 3vw, 18px);
  }
  
  h1 {
    font-size: clamp(40px, 3vw, 56px);
    line-height: 120%;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
  
  h2 {
    font-size: clamp(32px, 3vw, 48px);
    line-height: 120%;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
  
  h3 {
    font-size: clamp(24px, 3vw, 40px);
    line-height: 120%;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
  }
  
  h4 {
    font-size: clamp(20px, 3vw, 32px);
    line-height: 120%;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
  }
  
  h5 {
    font-size: clamp(18px, 3vw, 24px);
    line-height: 120%;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  h6 {
    font-size: clamp(16px, 3vw, 18px);
    line-height: 120%;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
`;



export const PageWrapper = styled.div`
    background: ${props => props.theme.white};
    padding: 9rem 1rem;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
        padding: 10rem 1rem;
    }
    
    @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        padding: 10.75rem 1rem;
    }
`;

export const PageContainer = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: auto;
  
   @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        max-width: 1170px;
     }

    ${props => props.fluid && css`
      padding: 0;
      margin: 0;
      max-width: 100% !important;
  `}
`;

export const PageContent = styled(motion.div)`
     display: grid;
     grid-row-gap: 4rem;
     grid-template-columns: repeat(12, 1fr);
     grid-template-rows: min-content;
     align-content: center;
     min-height: calc(100vh - 341px);
     min-height: calc(calc(var(--vh, 1vh) * 100) - 341px);
     
     @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        grid-column: 6/13;
        text-align: left;
     }
`;

export const HeadingH2 = styled(motion.h2)`
    color: ${props => props.theme.black};
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    font-size: clamp(32px,6vw,42px);
    line-height: 1.1;
    margin: 0;
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    max-width: 580px;
    margin: 0 auto;
  }
`;