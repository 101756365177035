import React from "react"
import { Helmet } from "react-helmet"
// import {useStaticQuery, graphql} from "gatsby";

// const query = graphql`
//   {
//     site {
//       siteMetadata {
//         siteTitle: title
//         siteDesc: description
//         siteUrl
//         author
//       }
//     }
//   }
// `;

const SEO = ({title, description}) => {

  // const{site} = useStaticQuery(query);
  // const{siteTitle, siteDesc} = site.siteMetadata;

  return <Helmet htmlAttributes={{lang:"lt"}} title={`${title}`}>SEO Component
    <meta name="description" content={description}/>
  </Helmet>
}

export default SEO
