import React, {useState, useEffect} from "react"
import {BohaboLogo, Hamburger} from "../assets/svg/icons-and-logos";
import Links from "../constants/links"
import styled from "styled-components"
import { Link } from "gatsby"

const Header = ({setMenuState, menuState}) => {

    const [setOpen] = useState(false);

    useEffect(() => {
        if (menuState === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    });

    return (
          <Nav>
              <NavLink to="/" onClick={() => setMenuState(false)}>
                  <Logo/>
              </NavLink>


              <HeaderLinks>
                  <Links/>
              </HeaderLinks>


              <Bars
                  className="hovered"
                  isOpen={menuState}
                  setOpen={setOpen}
                  onClick={() => setMenuState(!menuState)}
                  color={ "#000000" }
                  whileTap={{ scale: 0.9 }}
              />
          </Nav>
  )
};

export default Header

const Nav = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 112px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-items: center;
    z-index: 100;
    background: transparent;
    
    @media screen and (min-width: 1170px) {
    padding: 0.5rem calc((100vw - 1170px) / 2);
   }
`;

const HeaderLinks = styled.div`
    @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
        display: none;
    }
`;

const NavLink = styled(Link)`
   display: flex;
   align-items: center;
   text-decoration: none;
   height: 100%;
   cursor: pointer;
   margin-bottom: 0;
`;

const Bars = styled(Hamburger)`
   display: none;
   cursor: pointer;
   
   @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    display: block;
   }
`;

const Logo = styled(BohaboLogo)`
   height: 68px;
   
   @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        height: 64px;
    }
`;