import React from "react"

//Icons
import { Facebook, Instagram} from "../assets/svg/icons-and-logos";
import styled from "styled-components";

const data = [
  {
    id: 1,
    icon: <Facebook className="social-icon"></Facebook>,
    url: "https://www.facebook.com/bohabocornhole",
  },
  {
    id: 2,
    icon: <Instagram className="social-icon"></Instagram>,
    url: "https://www.instagram.com/bohabocornhole",
  },
];

const SocialLinks = () => {
  return (
    <SocialList>
      {data.map(link => {
        return (
        <SociaListItems key={link.id}>
        <a href={link.url} target="_blank" rel="noreferrer noopener nofollow">{link.icon}</a>
        </SociaListItems>
        )
      })}
    </SocialList>
  )
};

export default SocialLinks;

const SocialList = styled.ul`
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    list-style: none;
`;


const SociaListItems = styled.li`
    text-decoration: none;
     align-self: center;
     :not(:last-child){
        margin-right: 1rem;
     }
    
    a {
     text-transform: uppercase;
     fill: ${props => props.theme.black};
     font-weight: bold;
     letter-spacing: 0.5px;
     padding: 0rem;
     text-decoration: none;
     transition: 100ms;
     
     :hover {
      fill: ${props => props.theme.primary};
     }
     
    }
`;