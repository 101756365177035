import React from 'react';
import Links from "../constants/links";
import {AnimatePresence, motion} from "framer-motion";
import styled from "styled-components"
import SocialLinks from "../constants/socialLinks";


const Sidebar = ({menuState}) => {

  return (
      <>
        <AnimatePresence>
          {menuState && (
              <Overlay
                  initial={{y: '-100%'}}
                  exit={{y: '-100%'}}
                  animate={{y: menuState ? 0 : '-100%'}}
                  transition={{duration: .4, ease: [0.6, 0.05, -0.01, 0.9]}}
              >
                  <SidebarContainer>
                      <SidebarContent>
                          <SidebarLinks>
                              <Links/>
                          </SidebarLinks>
                          <SidebarSocialLinks>
                              <SocialLinks/>
                          </SidebarSocialLinks>
                      </SidebarContent>
                  </SidebarContainer>

              </Overlay>
          )}
        </AnimatePresence>
      </>
  );
};

export default Sidebar;

const Overlay = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 98;
  box-sizing: border-box;
  // @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
  //       display: none;
  //   }
`;

const SidebarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    position: relative;
    background: ${props => props.theme.white};
    height: 100vh;
    overflow: hidden;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarSocialLinks = styled.div`
  margin-top: 1em;
  ul {
    justify-content: center;
  }
  a {
   fill: ${props => props.theme.black};
  }
`;

const SidebarLinks = styled.div`
  ul {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  li {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
`;