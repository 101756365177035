import {createGlobalStyle} from "styled-components";

// Gilroy BlackItalic
import GilroyBlackItalicEot from './Gilroy/Gilroy-BlackItalic.eot';
import GilroyBlackItalicWoff2 from './Gilroy/Gilroy-BlackItalic.woff2';
import GilroyBlackItalicWoff from './Gilroy/Gilroy-BlackItalic.woff';
import GilroyBlackItalicTtf from './Gilroy/Gilroy-BlackItalic.ttf';

// Gilroy Bold
import GilroyBoldEot from './Gilroy/Gilroy-Bold.eot';
import GilroyBoldWoff2 from './Gilroy/Gilroy-Bold.woff2';
import GilroyBoldWoff from './Gilroy/Gilroy-Bold.woff';
import GilroyBoldTtf from './Gilroy/Gilroy-Bold.ttf';

// Gilroy Regular
import GilroyRegularEot from './Gilroy/Gilroy-Regular.eot';
import GilroyRegularWoff2 from './Gilroy/Gilroy-Regular.woff2';
import GilroyRegularWoff from './Gilroy/Gilroy-Regular.woff';
import GilroyRegularTtf from './Gilroy/Gilroy-Regular.ttf';

export const GlobalFonts = createGlobalStyle`

@font-face {    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBlackItalicEot});
    src: local('Gilroy BlackItalic'), local('Gilroy-BlackItalic'),
        url(${GilroyBlackItalicEot}) format('embedded-opentype'),
        url(${GilroyBlackItalicWoff2}) format('woff2'),
        url(${GilroyBlackItalicWoff}) format('woff'),
        url(${GilroyBlackItalicTtf}) format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBoldEot});
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(${GilroyBoldEot}) format('embedded-opentype'),
        url(${GilroyBoldWoff2}) format('woff2'),
        url(${GilroyBoldWoff}) format('woff'),
        url(${GilroyBoldTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url(${GilroyRegularEot});
        src: local('Gilroy Regular'), local('Gilroy-Regular'),
            url(${GilroyRegularEot}) format('embedded-opentype'),
            url(${GilroyRegularWoff2}) format('woff2'),
            url(${GilroyRegularWoff}) format('woff'),
            url(${GilroyRegularTtf}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
`;