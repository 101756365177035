import React from "react"
import styled from "styled-components"
import {BohaboLogoAlt} from "../assets/svg/icons-and-logos";
import {Link} from "gatsby"


const Footer = () => {
  return (
      <FooterContainer>
          <FooterContent>
              <FooterDesc>
                  <FooterLogoLink to="/">
                     <FooterLogo/>
                  </FooterLogoLink>
              </FooterDesc>
              <FooterLinkItems>
                  <FooterLinkTitle>Bohabo</FooterLinkTitle>
                  <FooterLink to="/">Pradžia</FooterLink>
                  <FooterLink to="/kontaktai">Kontaktai</FooterLink>
                  <FooterLink to="/apie-mus">Apie Mus</FooterLink>
                  <FooterLink to="/cornhole-nuoma">Cornhole Nuoma</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                  <FooterLinkTitle>Informacija</FooterLinkTitle>
                  <FooterLink to="/taisykles">Taisyklės</FooterLink>
                  <FooterLink to="/apie-cornhole">Apie Cornhole</FooterLink>
                  <FooterLink to="/kainos">Kainos</FooterLink>
                  <FooterLink to="/turnyras">Turnyras</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                  <FooterLinkTitle>Social Media</FooterLinkTitle>
                  <FooterLinkAlink href="https://www.facebook.com/bohabocornhole" target="_blank" rel="noreferrer noopener nofollow">Facebook</FooterLinkAlink>
                  <FooterLinkAlink href="https://www.instagram.com/bohabocornhole" target="_blank" rel="noreferrer noopener nofollow">Instagram</FooterLinkAlink>
              </FooterLinkItems>
          </FooterContent>

          <FooterCopyrightContent>
              <FooterCopyright>Copyright &copy; {new Date().getFullYear()} <a href="https://www.bohabo.com">Bohabo</a> all rights reserved</FooterCopyright>
          </FooterCopyrightContent>

      </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer`
  background: ${props => props.theme.black};
  margin-top: auto;
`;

const FooterContent = styled.div`
  padding: 6rem 1rem 5rem 1rem;
  max-width: 1170px;
  margin: auto;
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
   grid-row-gap: 1rem;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    text-align: center;
    grid-template-columns: 1fr;
  }
`;

const FooterDesc = styled.div`
  max-width: 250px;
  p {
    font-size: 16px;
    color: ${props => props.theme.white};
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    margin: 0 auto 1rem;
  }
`;

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 21px;
`;

const FooterLinkTitle = styled.h3`
  font-size: 21px;
  margin-bottom: 16px;
  margin-top: 0 !important;
  color: ${props => props.theme.white};
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme.white};
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-size: 16px;
  &:hover{
    color: ${props => props.theme.primary};
    transition: 100ms ease-out;
  }
`;

const FooterLinkAlink = styled.a`
  color: ${props => props.theme.white};
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-size: 16px;
  &:hover{
    color: ${props => props.theme.primary};
    transition: 100ms ease-out;
  }
`;


const FooterCopyrightContent = styled.div`
    margin: auto;
    padding: 1rem 1rem;
    max-width: 1170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    flex-direction: column;
  }
`;

const FooterLogo = styled(BohaboLogoAlt)`
  height: 108px;
  margin-bottom: 32px;
  transform: translateY(24px);
`;

const FooterCopyright = styled.p`
    color: #a9a9a9;
    font-weight: 400;
    padding: 0;
    margin: auto;
    
    a {
      color: #a9a9a9;
      text-decoration: none;
      font-weight: 700;
      &:hover{
        color: ${props => props.theme.primary};
      }
    }
    
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-bottom: 2rem;
  }
`;

const FooterLogoLink = styled(Link)`
  cursor: pointer;
`;




